import CmtCard from "@coremat/CmtCard";
import CmtCardContent from "@coremat/CmtCard/CmtCardContent";
import CmtCardHeader from "@coremat/CmtCard/CmtCardHeader";
import IntlMessages from "@jumbo/utils/IntlMessages";
import {
    FormControl,
    RadioGroup,
    Radio,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    Typography,
    Button,
    DialogTitle,
    DialogContent,
    Dialog
} from "@material-ui/core";

import DataGrid from "app/uielements/Table/DataGrid";
import {Close, RefreshSharp} from "@material-ui/icons";
import React, {useState} from "react";
import {TextField} from "app/uielements";
import CustomGridPanel from "app/uielements/Table/CustomGridPanel";
import {GridContainer, Loader} from "../uielements";
import NumberFormat from "react-number-format";
import {makeStyles} from "@material-ui/styles";

const Results = ({
                     rows,
                     className,
                     total,
                     pageSize,
                     handlePageChange,
                     handlePageCountChange,
                     handleSortModelChange,
                     sortModel,
                     handleFilterChange,
                     handleRefresh,
                     loading,
                     selectionModel,
                     handleSelection,
                     selectedRadioValue,
                     handleChange,
                     save,
                     setReference,
                     setRemarks,
                     remarks,
                     reference,
                     styles,
                     columns,
                     handleRowClick,
                     viewedRequests,
                     modalOpen,
                     details,
                     closeModal,
                     notSelectable,
                     CustomExportButton
                 }) => {

    const useStyles = makeStyles((theme) => ({
        textField: {
            width: '100%', '& .MuiOutlinedInput-root': {
                '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.error.main,
                },
            },
        },
    }));

    const classes = useStyles();

    const handleRefChange = (event) => {
        let inputValue = event.target.value;
        inputValue = inputValue.replace(/[^/\d]/g, '');

        if (inputValue.length >= 3 && inputValue.charAt(2) !== '/') {
            inputValue = inputValue.slice(0, 2) + '/' + inputValue.slice(2);
        }

        if (inputValue.length >= 6 && inputValue.charAt(5) !== '-') {
            inputValue = inputValue.slice(0, 5) + '-' + inputValue.slice(5);
        }

        inputValue = inputValue.slice(0, 9);
        setReference(inputValue);
    };

    const isError = reference.length > 0 && reference.length < 9;

    return (<CmtCard className={className}>
        <CmtCardHeader style={{padding: '0px', display: 'flex', justifyContent: 'end'}}>
            {handleRefresh !== null && (<IconButton onClick={handleRefresh} style={{padding: '0px'}}>
                <RefreshSharp/>
            </IconButton>)}
        </CmtCardHeader>
        <CmtCardContent style={{height: 500}} className={styles.grid}>
            <DataGrid
                showToolbar
                isExportable={true}
                CustomExportButton={CustomExportButton}
                rows={rows}
                columns={columns}
                pagination
                pageSize={pageSize}
                rowsPerPageOptions={[10, 20, 50, 100]}
                rowCount={total}
                paginationMode="server"
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageCountChange}
                sortingMode="server"
                sortModel={sortModel}
                onSortModelChange={handleSortModelChange}
                filterMode="server"
                onFilterModelChange={handleFilterChange}
                checkboxSelection
                onSelectionModelChange={handleSelection}
                selectionModel={selectionModel}
                loading={loading}
                components={{Panel: CustomGridPanel}}
                onRowClick={handleRowClick}
                style={{width: "100%", height: 400}}
                columnBuffer={2}
                disableColumnVirtualization
                // getRowClassName={getRowClassName}
                getRowClassName={(params) => `${viewedRequests.includes(params.id) ? 'request' : 'request-not'}-viewed`}
                isRowSelectable={(params) => {
                    return !notSelectable.includes(params.id);
                }}
                rowHeight={33}
                onRefresh={null}
            />


        </CmtCardContent>
        {selectionModel.length > 0 && (<CmtCard>
            <CmtCardContent>
                <CmtCard><CmtCardContent>
                    <FormControl component="fieldset">
                        <FormLabel component="legend" style={{marginBottom: "20px"}}>
                            <IntlMessages id="user.bill_recon.method"/>
                        </FormLabel>
                        <RadioGroup
                            aria-label="payment_type"
                            name="payment_type"
                            onChange={handleChange}

                        >
                            <Grid container xs={12}>
                                <Grid item xs={12} md={4}>
                                    <FormControlLabel
                                        value="bank_transfer"
                                        className={styles.fullWidth}
                                        control={<Radio/>}
                                        label={<IntlMessages id="user.bill_recon.bank"/>}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <FormControlLabel
                                        value="dialog"
                                        className={styles.fullWidth}
                                        control={<Radio/>}
                                        label={<IntlMessages id="user.bill_recon.dialog"/>}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <FormControlLabel
                                        value="mobitel"
                                        className={styles.fullWidth}
                                        control={<Radio/>}
                                        label={<IntlMessages id="user.bill_recon.mobitel"/>}
                                    />
                                    {/*<FormControlLabel*/}
                                    {/*    value="wallet"*/}
                                    {/*    className={styles.fullWidth}*/}
                                    {/*    control={<Radio/>}*/}
                                    {/*    label={<IntlMessages id="user.bill_recon.wallet"/>}*/}
                                    {/*/>*/}
                                    {/*<FormControlLabel*/}
                                    {/*    className={styles.fullWidth}*/}
                                    {/*    value="other"*/}
                                    {/*    control={<Radio/>}*/}
                                    {/*    label={<IntlMessages id="user.bill_recon.other"/>}*/}
                                    {/*/>*/}
                                </Grid>
                            </Grid>
                        </RadioGroup>
                        <GridContainer xs={12} md={12} style={{margin: "10px 0px 10px 0px"}}>
                            <Grid item xs={12} md={6} className={styles.formColumn}>
                                <FormControl style={{width: "100%"}}>
                                    <TextField
                                        variant="outlined"
                                        label={<IntlMessages id="biller.reference"/>}
                                        value={reference}
                                        onChange={handleRefChange}
                                        inputProps={{
                                            maxLength: 9,
                                        }}
                                        placeholder="YY/YY-NNN"
                                        error={isError}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6} className={styles.formColumn}>
                                <FormControl style={{width: "100%"}}>
                                    <TextField
                                        variant="outlined"
                                        label={<IntlMessages id="biller.remarks"/>}
                                        value={remarks}
                                        onChange={(event) => setRemarks(event.target.value)}
                                    />
                                </FormControl>
                            </Grid>
                        </GridContainer>
                        <Grid item xs={12} md={6} className={styles.formColumn}>
                            <Button variant="contained" disabled={!selectedRadioValue || isError} color="primary"
                                    onClick={() => save()}>submit</Button>
                        </Grid>
                    </FormControl>
                    {loading && <Loader/>}
                </CmtCardContent></CmtCard>


            </CmtCardContent>
        </CmtCard>)}

        <Dialog open={modalOpen} fullWidth>
            {details && (<>
                <IconButton size="small" onClick={closeModal} style={{width: 10, marginLeft: '570px'}}>
                    <Close color="error"/>
                </IconButton>
                <DialogTitle>Request ID {details.id} Details</DialogTitle>
                <CmtCard className={className} style={{width: 500, display: 'contents'}}>
                    <CmtCardContent>
                        <DialogContent style={{height: 245}}>
                            <Grid container xs={12} spacing={4}>
                                <Grid container item xs={6}>
                                    <Typography><b>Mobile No:</b></Typography>
                                </Grid>
                                <Grid container item xs={6}>
                                    <Typography>{details.user_mobile_no}</Typography>
                                </Grid>
                            </Grid>
                            {details.mobile_no !== 0 ? <Grid container xs={12} spacing={4}>
                                <Grid container item xs={6}>
                                    <Typography><b>Mobile to Pay: </b></Typography>
                                </Grid>
                                <Grid container item xs={6}>
                                    <Typography>{details.mobile_no}</Typography>
                                </Grid>
                            </Grid> : ''}
                            {details.account_number ? <>
                                <Grid container xs={12} spacing={4}>
                                    <Grid container item xs={6}>
                                        <Typography><b>Holder Name: </b></Typography>
                                    </Grid>
                                    <Grid container item xs={6}>
                                        <Typography>{details.holder_name}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container xs={12} spacing={4}>
                                    <Grid container item xs={6}>
                                        <Typography><b>NIC:</b></Typography>
                                    </Grid>
                                    <Grid container item xs={6}>
                                        <Typography>{details.holder_nic}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container xs={12} spacing={4}>
                                    <Grid container item xs={6}>
                                        <Typography><b>Account Number:</b></Typography>
                                    </Grid>
                                    <Grid container item xs={6}>
                                        <Typography>{details.account_number}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container xs={12} spacing={4}>
                                    <Grid container item xs={6}>
                                        <Typography><b>Bank:</b></Typography>
                                    </Grid>
                                    <Grid container item xs={6}>
                                        <Typography>{details.bank}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container xs={12} spacing={4}>
                                    <Grid container item xs={6}>
                                        <Typography><b>Branch:</b></Typography>
                                    </Grid>
                                    <Grid container item xs={6}>
                                        <Typography>{details.branch}</Typography>
                                    </Grid>
                                </Grid>
                            </> : ''}
                            <Grid container xs={12} spacing={4}>
                                <Grid container item xs={6}>
                                    <Typography><b>Requested Amount:</b></Typography>
                                </Grid>
                                <Grid container item xs={6}>
                                    <Typography>Rs.{details.amount}</Typography>
                                </Grid>
                            </Grid>
                            {details.reference_number ? <Grid container xs={12} spacing={4}>
                                <Grid container item xs={6}>
                                    <Typography><b>Reference Number:</b></Typography>
                                </Grid>
                                <Grid container item xs={6}>
                                    <Typography>{details.reference_number}</Typography>
                                </Grid>
                            </Grid> : ''}

                            {details.remarks ? <Grid container xs={12} spacing={4}>
                                <Grid container item xs={6}>
                                    <Typography><b>Remarks:</b></Typography>
                                </Grid>
                                <Grid container item xs={6}>
                                    <Typography>{details.remarks}</Typography>
                                </Grid>
                            </Grid> : ''}
                        </DialogContent>
                        <Grid container spacing={2} alignItems="end"
                              style={{marginTop: "6px", display: "flex", justifyContent: 'end'}}>
                            <Button variant="contained" color="primary" size="small"
                                    onClick={() => closeModal(true)}>Close</Button>
                        </Grid>
                    </CmtCardContent>
                </CmtCard>
            </>)}
        </Dialog>
    </CmtCard>);
};

export default Results;
