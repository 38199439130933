import showAlert from "app/hooks/useAlerts";
import useAxios from "app/hooks/useAxios";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import throttle from "lodash/throttle";
import moment from "moment";
import {useIntl} from "react-intl";
import useStyles from "./Styles";
import {Button, setRef, Typography} from "@material-ui/core";
import {GridToolbarContainer, GridToolbarExport} from "@material-ui/data-grid";
import {SystemUpdateAlt} from "@material-ui/icons";

const useData = ({status, handleClose}) => {
    const defaultValues = {
        end_date: '',
        start_date: '', // payment_method:'',
        payment_type: '',
        searching_payment_type: "",
        mobile_no: '',
        lottery_id: '',
        draw_number: '',
        reference_number: '',
        remarks: '',
        status: 'PENDING',
    };

    const {
        register, setValue, getValues, reset, formState: {errors}, handleSubmit,
    } = useForm({
        defaultValues,
    });

    const axios = useAxios();
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [newRows, setNewRows] = useState([]);
    const [params, setParams] = useState(null);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [filterModel, setFilterModel] = React.useState({items: []});
    const [expanded, setExpanded] = React.useState(false);
    const [selectionModel, setSelectionModel] = React.useState([]);
    const [selected, setSelected] = React.useState([]);
    const [notSelectable, setNotSelectable] = React.useState([]);
    const [selectedRadioValue, setSelectedRadioValue] = useState("");
    const [clickedRow, setClickedRow] = useState(null);
    const [reference, setReference] = useState('');
    const [remarks, setRemarks] = useState('');
    const [sortModel, setSortModel] = React.useState([]);
    const [submittedRequests, setSubmittedRequests] = React.useState([]);
    const [previousDownloadableRows, setPreviousDownloadableRows] = React.useState([]);

    const formatDate = (val) => val ? moment(val).format("yyyy-MM-DD") : '';

    const handleSelection = (newSelection) => {
        setSelectionModel(newSelection.selectionModel);
        const selectedIds = rows.filter((r) => newSelection.selectionModel.includes(r.id)).map((r) => r.id);

        setSelected((rows) => {
            const selection = [...selectedIds];
            rows.forEach((row) => {
                if (!rows.find((r) => r.id === row.id)) {
                    selection.push(row);
                }
            });

            return selection;
        });
    };

    useEffect(() => {
        let active = true;

        const selectedIds = rows
            .filter((row) => selected.includes(row.id))
            .map((r) => r.id);

        selectionModel.filter((s) => !selectedIds.includes(s));
        selectedIds.filter((s) => !selectionModel.includes(s));

        return () => {
            active = false;
        };
    }, [selected]);


    const handleChange = (event) => {
        setSelectedRadioValue(event.target.value);
    };

    const handleSortModelChange = (params) => {
        if (params.sortModel !== sortModel) {
            setSortModel(params.sortModel);
            setPage(0);
        }
    };

    const handleFilter = (params) => {
        setFilterModel(params.filterModel);
        setPage(0);
    };


    const handleFilterChange = React.useCallback(throttle(handleFilter, 500), []);

    const handlePageChange = (params) => {
        setPage(params.page);
    };

    const handlePageCountChange = (params) => {
        setPageSize(params.pageSize);
        setPage(0);
    };

    function CustomToolbar() {
        return (<GridToolbarContainer>
            <GridToolbarExport/>
        </GridToolbarContainer>);
    }


    const CustomExportButton = () => {
        const handleExport = async () => {
            const allColumns = [...columns, ...hiddenColumns];
            const visibleColumns = allColumns.filter((col) => col.field !== "view");
            const fields = visibleColumns.map((col) => col.field);

            let exportableRows = newRows;
            if (selectionModel.length > 0) {
                exportableRows = [...previousDownloadableRows, ...newRows.filter((row) => selectionModel.includes(row.id))];
            }

            const csvContent = [fields.join(','), ...exportableRows.map((row) => {
                const statusText = row.status === 1 ? 'Completed' : 'Pending';
                const rowData = fields.map((field) => {
                    return field === 'status' ? `"${statusText}"` : `"${row[field] || ''}"`;
                });
                return rowData.join(',');
            }),].join('\n');


            const blob = new Blob([csvContent], {type: 'text/csv'});

            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'data.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            setLoading(false);
        };

        return (<Button variant="outlined" size="small" color="primary" startIcon={<SystemUpdateAlt/>}
                        onClick={handleExport}>
            Export Data
        </Button>);
    };

    const searchResults = async (active = true, refresh) => {
        setLoading(true);
        const body = {
            page_count: pageSize, ...params,
            start_date: params ? formatDate(params.start_date) : '',
            end_date: params ? formatDate(params.end_date) : '',
            status: params ? params.status : 'PENDING',
            sortModel,
            filterModel: {
                items: filterModel.items
                    .filter((i) => i.value)
                    .map((i) => ({
                        ...i, columnField: `${i.columnField}`,
                    })),
            },
        };

        try {
            const response = await axios.post("/api/holdBalanceTransferRequests/view?page=" + (page + 1), body);

            console.log("Payment  value:", getValues().payment_type, "response:", response);

            setNewRows(response.data.data);
            const totalValue = response.data.total;

            if (!active) {
                return;
            }

            setRows(response.data.data);
            const notSelectable = response.data.data.filter((r) => r.status === 1).map((r) => r.id);
            setNotSelectable(notSelectable);
            setTotal(totalValue);
            if (!refresh) {
                setSelectionModel((prev) => [...prev, ...selected]);
                setPreviousDownloadableRows((prev) => [...prev, ...rows.filter((row) => selected.includes(row.id))])
            }

            if (refresh) {

            }

            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    useEffect(() => {
        let active = true;

        (async () => await searchResults(active))();

        return () => {
            active = false;
        };
    }, [page, pageSize, total, params, sortModel, filterModel]);

    const search = (data) => {
        console.log("Search data:", data);
        setParams({...data});
        setExpanded(!expanded);
    };

    const handleRefresh = async (active) => {
        setPage(0);
        reset(defaultValues);
        setViewedRequests([]);
        setSelected([]);
        setSelectionModel([]);
        let refresh = true;
        await searchResults(active, refresh);
    }

    const onReset = () => {
        reset(defaultValues);
        setSelectionModel([]);
        setParams(null);
    };

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const onSearch = handleSubmit(search);

    const [details, setDetails] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [viewedRequests, setViewedRequests] = React.useState([]);

    const openModal = (event, data) => {
        event.stopPropagation();
        setDetails(data);
        setViewedRequests((prevIds) => [...prevIds, data.id]);
        setModalOpen(true);
    };


    const closeModal = () => {
        setDetails('');
        setModalOpen(false);
    };

    const intl = useIntl();

    const styles = useStyles();

    const handleOnChange = (event) => setValue(event.target.name, event.target.value, {shouldValidate: true});

    const columns = [{
        field: "id", headerName: intl.formatMessage({id: "biller.request_id"}), flex: 0.7,
    },

        {
            field: "user_mobile_no", headerName: intl.formatMessage({id: "biller.mobile_no"}), flex: 1,
        }, {
            field: "draw_name", headerName: intl.formatMessage({id: "biller.draw_name"}), flex: 1,
        },

        {
            field: "serial_no", headerName: intl.formatMessage({id: "biller.serial"}), flex: 1,
        }, {
            field: "amount", headerName: intl.formatMessage({id: "biller.amount"}), flex: 0.7,
        }, {
            field: "payment_type", headerName: intl.formatMessage({id: "biller.type"}), flex: 0.8,
        }, {
            field: "bank", headerName: intl.formatMessage({id: "biller.bank_name"}), flex: 0.8,
        }, {
            field: "created_at", headerName: intl.formatMessage({id: "biller.created_at"}), flex: 0.8,
        },

        {
            field: "status",
            headerName: intl.formatMessage({id: "biller.status"}),
            flex: 0.8,
            renderCell: (params) => (<Typography variant="subtitle2" style={{
                display: 'flex',
                whiteSpace: 'pre-line',
                fontWeight: "bold",
                fontSize: '15px',
                marginBottom: '6px',
                color: "white"
            }}>
                <Button variant="contained" color="primary" size="small"
                        style={{fontSize: '9px', background: statusColor(params.value, params.id), width: '90px'}}>
                    {params.value === 1 ? 'Completed' : (submittedRequests.includes(params.id) ? 'Submitted ' : 'Pending')}
                </Button>

                {/* {params.value} */}
            </Typography>),
        }, {
            field: 'view', headerName: 'Actions', flex: 0.8, renderCell: (params) => (<Typography variant="subtitle2"
                                                                                                  style={{
                                                                                                      display: 'flex',
                                                                                                      whiteSpace: 'pre-line',
                                                                                                      fontSize: '10px',
                                                                                                      marginBottom: '6px'
                                                                                                  }}>

                    <Button variant="contained" color="primary" size="small"
                            onClick={(event) => openModal(event, params.row)}
                            style={{fontSize: '9px'}}>
                        View
                    </Button>
                </Typography>

            ),
        },];

    const hiddenColumns = [
        {
            field: "requested_channel", headerName: intl.formatMessage({id: "biller.requested_channel"}), flex: 0.8,
        },
        {
            field: "holder_name", headerName: intl.formatMessage({id: "biller.holder_name"}), flex: 0.8,
        },
        {
            field: "holder_nic", headerName: intl.formatMessage({id: "biller.holder_nic"}), flex: 0.8,
        },

        {
            field: "branch", headerName: intl.formatMessage({id: "biller.branch"}), flex: 0.8,
        },

        {
            field: "account_number", headerName: intl.formatMessage({id: "biller.account_number"}), flex: 0.8,
        },

        {
            field: "reference_number", headerName: intl.formatMessage({id: "biller.reference_number"}), flex: 0.8,
        },

        {
            field: "remarks", headerName: intl.formatMessage({id: "biller.remarks"}), flex: 0.8,
        },

        {
            field: "owner", headerName: intl.formatMessage({id: "biller.bank_name"}), flex: 0.8,
        },

        {
            field: "registered_nicno", headerName: intl.formatMessage({id: "biller.bank_name"}), flex: 0.8,
        },];

    const handleRowClick = (event, params) => {
        setClickedRow(params.row);
        console.log("Row clicked:", params.row);
    };

    const getRowClassName = (params) => {
        const rowId = params.row.id;
        console.log("rowId", rowId);
        return viewedRequests.includes(rowId) ? 'with-winning--present' : '';
    };


    const statusColor = (status, id) => {
        if (status === 1) {
            return 'rgb(3,156,3)';
        } else if (submittedRequests.includes(id)) {
            return 'rgb(87,87,87)';
        } else {
            return 'rgb(234,152,3)';
        }
    };

    const save = async data => {
        setLoading(true);

        if (!clickedRow && selectionModel.length === 0) {
            showAlert("error", "No row selected.");
            setLoading(false);
            return;
        }
        try {

            const request_ids = selectionModel;

            // const selectedRowData = rows
            //     .filter((row) => selectionModel.includes(row.id))
            //     .map(selectedRow => {
            //         return {
            //             serial_no: selectedRow.serial_no,
            //             user_mobile_no: selectedRow.user_mobile_no,
            //             amount: selectedRow.amount,
            //             payment_type: selectedRow.payment_type,
            //             status: selectedRow.status,
            //         };
            //     });

            const data = {
                request_ids, payment_type: selectedRadioValue, remarks: remarks, reference_number: reference,
            }

            console.log("Billed data", data);

            setSubmittedRequests(request_ids);

            const response = await axios.post("/api/completeHoldPayTransactionRequest", data);


            if (response.status === 200) {
                showAlert('success', 'Funds transferred successfully')
                setSelectionModel([]);
                reference('')
                console.log("data:", response)
                setSubmittedRequests(request_ids);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.error('Error sending POST request:', error);
        }
    }

    return {
        register,
        setValue,
        getValues,
        errors,
        onSearch,
        onReset,
        loading,
        rows,
        total,
        pageSize,
        handlePageChange,
        handlePageCountChange,
        handleSortModelChange,
        sortModel,
        handleFilterChange,
        handleClose,
        handleSubmit,
        handleRefresh,
        expanded,
        handleExpandClick,
        setLoading,
        CustomToolbar,
        selected,
        setSelected,
        selectionModel,
        setSelectionModel,
        handleSelection,
        selectedRadioValue,
        setSelectedRadioValue,
        handleChange,
        statusColor,
        setClickedRow,
        save,
        reference,
        setReference,
        remarks,
        setRemarks,
        styles,
        columns,
        handleRowClick,
        viewedRequests,
        modalOpen,
        details,
        closeModal,
        notSelectable,
        CustomExportButton
    };
};

export default useData;
