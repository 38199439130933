import React, {useEffect, useState} from 'react';
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import {Tooltip} from "@material-ui/core";
import CachedIcon from '@material-ui/icons/Cached';
import axios from "axios";
import DoneIcon from "@material-ui/icons/Done";
import FaceIcon from '@material-ui/icons/Face';


const HeaderRevenue = () => {
    const [loading, setLoading] = useState(false);
    const [apBalance, setApBalance] = useState(null)
    const id = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")).id : '';
    const permissions = localStorage.getItem("permissions")
    const baseUrl = process.env.REACT_APP_BASE_URL;


    async function getApBalance() {
        try {
            const response = await axios.get(`${baseUrl}/api/user/apcredit/balance/${id}`);
            const balance = parseFloat(response.data.acccount_balance).toFixed(2);
            setApBalance(balance);
        } catch (error) {
            console.error("Error fetching AP balance:", error);
        }
    }

    useEffect(() => {
        getApBalance();
    }, [permissions]);

    const handleClick = () => {
        getApBalance();
    };

    const handleDelete = () => {
        console.info('You clicked the delete icon.');
    };


    return (<>
        {localStorage.getItem("role") === "agent" ? <Tooltip title="Total Revenue">
            <Chip
                avatar={<Avatar>Rs</Avatar>}
                label={apBalance}
                onDelete={handleClick}
                deleteIcon={<CachedIcon />}
            />

        </Tooltip> : ""}
    </>);
};

export default HeaderRevenue;
