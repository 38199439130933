import React, {useEffect, useState} from "react";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import DateFnsUtils from "@date-io/date-fns";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {GridContainer, Select, TextField} from "app/uielements";
import IntlMessages from "@jumbo/utils/IntlMessages";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
    textPrimary: {
        color: theme.palette.primary.main,
    },
    mbSpace: {
        marginBottom: 16,
    },
    mtO: {
        margin: 0,
    },
}));

const SearchBillerForm = ({
                              onSearch,
                              onDownload,
                              lotteryList,
                              onReset,
                              register,
                              errors,
                              setValue,
                              getValues,
                          }) => {
    const handleOnChange = (evt) => {
        setValue(evt.target.name, evt.target.value, {shouldValidate: true});
        console.log('select', evt.target.value)
    };

    const classes = useStyles();

    const paymentTypes = [
        {
            value: null,
            label: <IntlMessages id="options.select"/>,
        },
        ...[{'value': 'bank_transfer', 'label': 'Bank Transfer'},
            {'value': 'wallet', 'label': 'Wallet'},
            //  {'value':'wallet','label':'Hold Transfer'},
            {'value': 'mobitel', 'label': 'Mobitel'},
            {'value': 'dialog', 'label': 'Dialog'},
            {'value': 'airtel', 'label': 'Airtel'},
            {'value': 'other', 'label': 'Other'}],

    ]

    const status = [
        ...[{'value': 'PENDING', 'label': 'Pending'},
            {'value': 'COMPLETED', 'label': 'Completed'},
            {'value': 0, 'label': 'All'}],
    ]

    const downloadTypes = [
        {
            value: "csv",
            label: <IntlMessages id="draws.download.csv"/>,
        },
        {
            value: "xlsx",
            label: <IntlMessages id="draws.download.xlsx"/>,
        },
        {
            value: "xls",
            label: <IntlMessages id="draws.download.xls"/>,
        },
        {
            value: "pdf",
            label: <IntlMessages id="draws.download.pdf"/>,
        },
    ];


    return (
        <Box>
            <Box>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <GridContainer>
                        <Grid item xs={12} sm={2}>
                            <FormControl style={{width: "100%"}} variant="outlined">
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    id="mobile_no"
                                    name="mobile_no"
                                    label={<IntlMessages id="biller.mobile_no"/>}
                                    {...register("mobile_no")}
                                    value={getValues().mobile_no}
                                    onChange={handleOnChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Grid>
                                <FormControl style={{width: "100%"}} variant="outlined">
                                    <Select
                                        variant="outlined"
                                        size="small"
                                        id="payment_type"
                                        name="payment_type"
                                        label={<IntlMessages id="biller.payment"/>}
                                        // InputProps={{ inputProps: { min: 0 } }}
                                        // {...register("payment_type")}
                                        {...register("payment_type", {})}
                                        value={getValues().payment_type}
                                        onChange={handleOnChange}

                                        options={paymentTypes}
                                        error={!!errors.payment_type}
                                        helperText={errors.payment_type?.message}

                                    />
                                </FormControl>

                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Grid>
                                <FormControl style={{width: "100%"}} variant="outlined">
                                    <Select
                                        variant="outlined"
                                        size="small"
                                        id="status"
                                        name="status"
                                        label={<IntlMessages id="biller.status"/>}
                                        {...register("status", {})}
                                        value={getValues().status}
                                        onChange={handleOnChange}

                                        options={status}
                                        error={!!errors.status}
                                        helperText={errors.status?.message}
                                    />

                                </FormControl>

                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <KeyboardDatePicker
                                variant="inline"
                                size="small"
                                inputVariant="outlined"
                                format="MM/dd/yyyy"
                                margin="normal"
                                id="start_date"
                                name="start_date"
                                label={<IntlMessages id="sms.start_date"/>}
                                fullWidth
                                className={classes.mtO}
                                {...register("start_date")}
                                value={getValues().start_date}
                                onChange={(val) =>
                                    setValue("start_date", val, {shouldValidate: true})
                                }
                                KeyboardButtonProps={{
                                    "aria-label": "change date",
                                }}
                                error={!!errors.start_date}
                                helperText={errors.start_date?.message}
                                autoOk
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <KeyboardDatePicker
                                variant="inline"
                                size="small"
                                inputVariant="outlined"
                                format="MM/dd/yyyy"
                                margin="normal"
                                id="end_date"
                                name="end_date"
                                label={<IntlMessages id="sms.end_date"/>}
                                fullWidth
                                className={classes.mtO}
                                {...register("end_date")}
                                value={getValues().end_date}
                                onChange={(val) =>
                                    setValue("end_date", val, {shouldValidate: true})
                                }
                                KeyboardButtonProps={{
                                    "aria-label": "change date",
                                }}
                                error={!!errors.end_date}
                                helperText={errors.end_date?.message}
                                autoOk
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Grid>
                                <FormControl style={{width: "100%"}} variant="outlined">
                                    <Select
                                        variant="outlined"
                                        size="small"
                                        id="lottery_id"
                                        name="lottery_id"
                                        label={<IntlMessages id="biller.lottery_name"/>}
                                        // InputProps={{ inputProps: { min: 0 } }}
                                        // {...register("lottery_id")}
                                        {...register("lottery_id", {})}
                                        value={getValues().lottery_id}
                                        onChange={handleOnChange}

                                        options={lotteryList}
                                        error={!!errors.lottery_id}
                                        helperText={errors.lottery_id?.message}
                                    />

                                </FormControl>

                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <FormControl style={{width: "100%"}} variant="outlined">
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    id="draw_number"
                                    name="draw_number"
                                    label={<IntlMessages id="biller.draw_number"/>}
                                    {...register("draw_number")}
                                    value={getValues().draw_number}
                                    onChange={handleOnChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <FormControl style={{width: "100%"}} variant="outlined">
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    id="reference"
                                    name="reference"
                                    label={<IntlMessages id="biller.reference"/>}
                                    {...register("reference_number")}
                                    value={getValues().reference}
                                    onChange={handleOnChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={2}
                              style={{display: 'flex', alignItems: 'end', justifyContent: 'center'}}>
                            <Box display="flex" alignItems="center" justifyContent="flex-end">
                                <Box ml={1}>
                                    <Button variant="outlined" onClick={onReset}>
                                        <IntlMessages id="sms.reset"/>
                                    </Button>
                                </Box>
                                <Box ml={1}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={onSearch}

                                    >
                                        <IntlMessages id="sms.search"/>
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>
                    </GridContainer>
                </MuiPickersUtilsProvider>
            </Box>
        </Box>
    );
};

export default SearchBillerForm;
