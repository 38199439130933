import showAlert from "app/hooks/useAlerts";
import useAxios from "app/hooks/useAxios";
import FileSaver from "file-saver";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import throttle from "lodash/throttle";
import moment from "moment";

const useData = ({ status }) => {
  const defaultValues = {
    lottery_id: null,
    type: "csv",
    draw_number: null,
    start_date: null,
    end_date: null,
    min_price: null,
    max_price: null,
  };

  const {
    register,
    setValue,
    getValues,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues,
  });

  const axios = useAxios();
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [params, setParams] = useState(null);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  let api_url='',sortField='',sortOrder='';

  const [filterModel, setFilterModel] = React.useState({ items: [] });

  switch (status){
    case 'sales':{
      api_url=`api/report/sales/download`
      sortField='draw_date';
      sortOrder='desc';
    };break;
    case 'salesdetail':{
      api_url=`api/report/sales-detail/download`
      sortField='draw_date';
      sortOrder='desc';
    };break;
    case 'revenue':{
      api_url=`api/report/revenue/download`
      sortField='draw_date';
      sortOrder='desc';
    };break;
    case 'highwinning':{
      api_url=`api/report/highwin/download`
      sortField='draw_date';
      sortOrder='desc';
    };break;
    case 'wallettransfer':{
      api_url=`api/report/wallet-transfer/download`
      sortField='transdate';
      sortOrder='desc';
    };break;
    case 'walletbalance':{
      api_url=`api/report/wallet-balance/download`
      sortField='transxdate';
      sortOrder='desc';
      console.log(defaultValues)
    };break;
    case 'salestracking':{
      console.log("hi");
      api_url=`api/report/wallet-balance/download`
      sortField='transxdate';
      sortOrder='desc';
    };break;
    case 'agentssales':{
      api_url=`api/report/agentssales/download`
      sortField='draw_date';
      sortOrder='desc';

    };break;

    default:{
      api_url=`/api/ticket/excel/download`;
      sortField='serial_no';
      sortOrder='asc';

   }
  }

  const [sortModel, setSortModel] = React.useState([
    { field: sortField, sort: sortOrder },
  ]);


  const formatDate = (val) => moment(val).format("yyyy-MM-DD");

  const handleSortModelChange = (params) => {
    console.log("param",params)
    if (params.sortModel !== sortModel) {
      setSortModel(params.sortModel);
      setPage(0);
    }
  };

  const handleFilter = (params) => {

    setFilterModel(params.filterModel);
    setPage(0);
  };

  const handleFilterChange = React.useCallback(throttle(handleFilter, 500), []);

  const handlePageChange = (params) => {

    setPage(params.page);
  };

  const handlePageCountChange = (params) => {
    console.log("params.page");
    setPageSize(params.pageSize);
    setPage(0);
  };

  const searchResults = async (active) => {
    try {
      if (!params) return;
      setLoading(true);

      const response = await axios.post(
        api_url+"?page=" + (page + 1),
        {
          page_count: pageSize,
          ...params,
          start_date: formatDate(params.start_date),
          end_date: formatDate(params.end_date),
          status,
          type: "json",
          sortModel,
          filterModel: {
            items: filterModel.items
              .filter((i) => i.value)
              .map((i) => ({
                ...i,
                columnField: `tickets.${i.columnField}`,
              })),
          },
        }
      );
      // console.log()
      const newRows = response.data.data;
      const totalValue = response.data.total;

      if (!active) {
        return;
      }

      setRows(newRows.map(r => ({...r, id: (r.serial_no? r.serial_no:r.id)})));
      setTotal(totalValue);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    let active = true;

    (async () => await searchResults(active))();

    return () => {
      active = false;
    };
  }, [page, total, pageSize, params, sortModel, filterModel]);

  const search = (data) => {

    setParams({...data});
  };

  const download = (data) => {
    try {
      setLoading(true);
      axios
        .post(
          api_url,
          {
            ...data,
            start_date: formatDate(data.start_date),
            end_date: formatDate(data.end_date),
            status,
          },
          { responseType: "blob" }
        )
        .then((response) => {
          // Log somewhat to show that the browser actually exposes the custom HTTP header
          const effectiveFileName = `${data.draw_number || (data.start_date.toISOString().slice(0, 10)+' - '+data.end_date.toISOString().slice(0, 10)) || ''}_${status}_report.${data.type}`;

          // Let the user save the file.
          FileSaver.saveAs(response.data, effectiveFileName);
          setLoading(false);
        })
        .catch((response) => {
          console.error(
            "Could not Download the report from the backend.",
            response
          );
          setLoading(false);
        });
    } catch (error) {}
  };

  const onReset = () => {
    reset(defaultValues);
    setParams(null);
    setRows([])
  };

  const onSearch = handleSubmit(search);
  const onDownload = handleSubmit(download);

  return {
    register,
    setValue,
    getValues,
    errors,
    onSearch,
    onReset,
    onDownload,
    loading,
    rows,
    total,
    pageSize,
    handlePageChange,
    handlePageCountChange,
    handleSortModelChange,
    sortModel,
    handleFilterChange,
  };
};

export default useData;
