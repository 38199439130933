import React from "react";

import PageContainer from "@jumbo/components/PageComponents/layouts/PageContainer";
import IntlMessages from "@jumbo/utils/IntlMessages";
import {Box, Grid} from "@material-ui/core";
import {useParams} from "react-router-dom";
import useStyles from "./Styles";

import useData from "./State";
import {useIntl} from "react-intl";
import Results from "./Results";
import SearchBiller from "./SearchBiller";
import useLotteryList from "../hooks/useLotteries";

const ViewReport = ({}) => {
    let {status} = useParams();
    const intl = useIntl();
    const breadcrumbs = [
        {label: "Home", link: "/"},
        {
            label: <IntlMessages id={"biller.title"}/>,
            link: "/biller/",
            isActive: true,
        },
    ];

    const classes = useStyles();
    const {lotteryList} = useLotteryList();

    const {
        register,
        getValues,
        errors,
        setValue,
        onReset,
        onSearch,
        loading,
        rows,
        total,
        pageSize,
        handlePageChange,
        handlePageCountChange,
        handleSortModelChange,
        sortModel,
        handleFilterChange,
        resend,
        handleSelection,
        handleRefresh,
        expanded,
        handleExpandClick,
        setLoading,
        selectionModel,
        setSelectionModel,
        selected,
        setSelected,
        selectedRadioValue,
        setSelectedRadioValue,
        handleChange,
        statusColor,
        setClickedRow,
        save,
        onDownload,
        setRemarks,
        remarks,
        setReference,
        reference,
        styles,
        columns,
        handleRowClick,
        viewedRequests,
        modalOpen,
        details,
        closeModal,
        notSelectable,
        CustomToolbar,
        CustomExportButton
    } = useData({status});
    return (
        <PageContainer
            heading={<IntlMessages id="biller.title"/>}
            breadcrumbs={breadcrumbs}
        >
            <Grid container spacing={3}>
                <Grid item xs={12} xl={12} className={classes.orderLg2}>
                    <Box mb={6}>
                        <SearchBiller
                            getValues={getValues}
                            lotteryList={lotteryList}
                            onSearch={onSearch}
                            onReset={onReset}
                            register={register}
                            setValue={setValue}
                            errors={errors}
                            title={intl.formatMessage({id: "biller.form.title"})}
                            onDownload={onDownload}
                            status={status}
                            expanded={expanded}
                            handleExpandClick={handleExpandClick}
                        />
                    </Box>
                    <Box>
                        <Results
                            title={<IntlMessages id="biller.results.title"/>}
                            rows={rows}
                            total={total}
                            pageSize={pageSize}
                            loading={loading}
                            setLoading={setLoading}
                            handlePageChange={handlePageChange}
                            handlePageCountChange={handlePageCountChange}
                            handleSortModelChange={handleSortModelChange}
                            sortModel={sortModel}
                            handleFilterChange={handleFilterChange}
                            resend={resend}
                            selectionModel={selectionModel}
                            setSelectionModel={setSelectionModel}
                            selected={selected}
                            setSelected={setSelected}
                            handleSelection={handleSelection}
                            handleRefresh={handleRefresh}
                            selectedRadioValue={selectedRadioValue}
                            setSelectedRadioValue={setSelectedRadioValue}
                            handleChange={handleChange}
                            statusColor={statusColor}
                            setClickedRow={setClickedRow}
                            save={save}
                            setReference={setReference}
                            reference={reference}
                            setRemarks={setRemarks}
                            remarks={remarks}
                            styles={styles}
                            columns={columns}
                            handleRowClick={handleRowClick}
                            viewedRequests={viewedRequests}
                            modalOpen={modalOpen}
                            details={details}
                            closeModal={closeModal}
                            notSelectable={notSelectable}
                            CustomToolbar={CustomToolbar}
                            CustomExportButton={CustomExportButton}
                        />
                    </Box>
                </Grid>
            </Grid>
            {/*<Backdrop className={classes.backdrop} open={loading}>*/}
            {/*  <CircularProgress color="inherit" />*/}
            {/*</Backdrop>*/}
        </PageContainer>
    );
};

export default ViewReport;
