import React, {useEffect} from "react";
import useData from "./State";
import {useIntl} from "react-intl";
import DataGrid from "app/uielements/Table/DataGrid";
import {DialogModal} from "app/uielements";
import useStyles from "./Styles";
import CustomGridPanel from "app/uielements/Table/CustomGridPanel";
import {
    Box,
    FormControl,
    RadioGroup,
    Radio,
    FormControlLabel,
    FormLabel,
    Grid,
    FormHelperText,
} from "@material-ui/core";
import IntlMessages from "@jumbo/utils/IntlMessages";
import Select from "app/uielements/Select/Select";
import {TextField} from "app/uielements";

const BillRecon = ({open, handleClose, id, lotteries, user}) => {
    const {
        rows,
        total,
        loading,
        pageSize,
        handlePageChange,
        handlePageCountChange,
        setId,
        setRows,
        handleSortModelChange,
        sortModel,
        handleFilterChange,
        columns,
        handleSelection,
        selectionModel,
        register,
        getValues,
        errors,
        setValue,
        options,
        submit,
    } = useData({lotteries, handleClose});

    useEffect(() => {
        setId(id);
    }, [id]);

    const styles = useStyles();

    const intl = useIntl();

    const format = (id) =>
        intl.formatMessage({
            id: `user.bill_recon.${id}`,
        });

    const isBankPayment =
        selectionModel.length > 0 && getValues().payment_type === "bank_transfer";

    const isBankTransferRequest =
        selectionModel.length > 0 && getValues().payment_type === "bank_transfer_request";

    const isReloadRequest =
        selectionModel.length > 0 && getValues().payment_type === "reload_request";

    const isOther =
        selectionModel.length > 0 && getValues().payment_type === "other";

    const isMobile =
        selectionModel.length > 0 &&
        (getValues().payment_type === "mobile" ||
            getValues().payment_type === "reload");

    const handleOnChange = (event) =>
        setValue(event.target.name, event.target.value, {shouldValidate: true});

    if (localStorage.getItem("role") != "dialog") {
        return (
            <DialogModal
                isOpen={open}
                handleClose={handleClose}
                handlePrimaryButton={submit}
                handleSecondaryButton={handleClose}
                primaryButtonLabel={format("save")}
                secondaryButtonLabel={format("cancel")}
                title={format("title")}
                loading={loading}
                primaryDisabled={selectionModel.length === 0}
            >
                <div className={styles.modelContent}>
                    <div className={styles.scrollbarRoot}>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            pagination
                            pageSize={pageSize}
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            rowCount={total}
                            paginationMode="server"
                            onPageChange={handlePageChange}
                            onPageSizeChange={handlePageCountChange}
                            sortingMode="server"
                            sortModel={sortModel}
                            onSortModelChange={handleSortModelChange}
                            filterMode="server"
                            onFilterModelChange={handleFilterChange}
                            onSelectionModelChange={handleSelection}
                            selectionModel={selectionModel}
                            checkboxSelection
                            loading={loading}
                            components={{Panel: CustomGridPanel}}
                            isRowSelectable={(params) => {
                                return params.row.paid_status !== 'Inprogress';
                            }}
                        />
                    </div>
                    {selectionModel.length > 0 && (
                        <Box p={2}>
                            <FormControl error={errors.payment_type} component="fieldset">
                                <FormLabel component="legend">
                                    <IntlMessages id="user.bill_recon.method"/>
                                </FormLabel>
                                <RadioGroup
                                    aria-label="payment_type"
                                    name="payment_type"
                                    {...register("payment_type", {
                                        required: {
                                            value: true,
                                            message: <IntlMessages id="errors.required"/>,
                                        },
                                    })}
                                    value={getValues().payment_type}
                                    onChange={handleOnChange}
                                    error={errors.payment_type}
                                    helperText={errors.payment_type?.message}
                                >
                                    <Grid container xs={12}>
                                        <Grid item xs={12} md={4}>
                                            <FormControlLabel
                                                value="bank_transfer"
                                                className={styles.fullWidth}
                                                control={<Radio/>}
                                                label={<IntlMessages id="user.bill_recon.bank"/>}
                                            />

                                            <FormControlLabel
                                                value="cash"
                                                className={styles.fullWidth}
                                                control={<Radio/>}
                                                label={<IntlMessages id="user.bill_recon.cash"/>}
                                            />
                                            <FormControlLabel
                                                value="ezcash"
                                                className={styles.fullWidth}
                                                control={<Radio/>}
                                                label={<IntlMessages id="user.bill_recon.ezcash"/>}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormControlLabel
                                                value="mcash"
                                                className={styles.fullWidth}
                                                control={<Radio/>}
                                                label={<IntlMessages id="user.bill_recon.mcash"/>}
                                            />
                                            <FormControlLabel
                                                value="mobile"
                                                className={styles.fullWidth}
                                                control={<Radio/>}
                                                label={<IntlMessages id="user.bill_recon.mobile"/>}
                                            />
                                            <FormControlLabel
                                                value="reload"
                                                className={styles.fullWidth}
                                                control={<Radio/>}
                                                label={<IntlMessages id="user.bill_recon.reload"/>}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormControlLabel
                                                value="wallet"
                                                className={styles.fullWidth}
                                                control={<Radio/>}
                                                label={<IntlMessages id="user.bill_recon.wallet"/>}
                                            />
                                            <FormControlLabel
                                                className={styles.fullWidth}
                                                value="other"
                                                control={<Radio/>}
                                                label={<IntlMessages id="user.bill_recon.other"/>}
                                            />
                                        </Grid>
                                    </Grid>
                                </RadioGroup>
                                <Box p={2}>
                                    <FormHelperText>{errors.payment_type?.message}</FormHelperText>
                                </Box>
                            </FormControl>
                            <FormControl error={errors.payment_type} component="fieldset" style={{width: '100%'}}>
                                <FormLabel component="legend">
                                    <IntlMessages id="user.bill_recon.transfer_request_method"/>
                                </FormLabel>
                                <RadioGroup
                                    row
                                    aria-label="payment_type"
                                    name="payment_type"
                                    {...register("payment_type", {
                                        required: {
                                            value: true,
                                            message: <IntlMessages id="errors.required"/>,
                                        },
                                    })}
                                    value={getValues().payment_type}
                                    onChange={handleOnChange}
                                    error={errors.payment_type}
                                    helperText={errors.payment_type?.message}
                                >
                                    {/*<Grid container xs={12} md={12}>*/}
                                    <Grid item xs={12} md={4}>
                                        <FormControlLabel
                                            value="bank_transfer_request"
                                            className={styles.fullWidth}
                                            control={<Radio/>}
                                            label={<IntlMessages id="user.bill_recon.bank"/>}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormControlLabel
                                            value="reload_request"
                                            className={styles.fullWidth}
                                            control={<Radio/>}
                                            label={<IntlMessages id="user.bill_recon.reload"/>}
                                        />
                                    </Grid>
                                    {/*</Grid>*/}
                                </RadioGroup>
                                <Box p={2}>
                                    <FormHelperText>{errors.payment_type?.message}</FormHelperText>
                                </Box>
                            </FormControl>
                            {(!isBankTransferRequest && !isReloadRequest) && (
                                <TextField
                                    required
                                    {...register("reference_number", {
                                        required: {
                                            value: true,
                                            message: <IntlMessages id="errors.required"/>,
                                        },
                                    })}
                                    id="reference_number"
                                    name="reference_number"
                                    value={getValues().reference_number}
                                    onChange={handleOnChange}
                                    variant="outlined"
                                    label={format("reference_number")}
                                    error={errors.reference_number}
                                    helperText={errors.reference_number?.message}
                                />
                            )
                            }
                        </Box>
                    )}

                    {isBankPayment && (
                        <Box p={2}>
                            <Grid container xs={12} spacing={2}>
                                <Grid item xs={12} className={styles.formColumn}>
                                    <IntlMessages id="user.bill_recon.bank_details"/>
                                </Grid>
                                <Grid item xs={12} md={6} className={styles.formColumn}>
                                    <Select
                                        options={options}
                                        label={<IntlMessages id="user.bill_recon.account_number"/>}
                                        variant="outlined"
                                        name="account_number"
                                        {...register("account_number", {
                                            required: {
                                                value: isBankPayment,
                                                message: <IntlMessages id="errors.required"/>,
                                            },
                                        })}
                                        value={getValues().account_number}
                                        onChange={handleOnChange}
                                        error={errors.account_number}
                                        helperText={errors.account_number?.message}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} className={styles.formColumn}></Grid>
                            </Grid>
                        </Box>
                    )}
                    {isMobile && (
                        <Box p={2}>
                            <Grid container xs={12} spacing={2}>
                                <Grid item xs={12} className={styles.formColumn}>
                                    <IntlMessages id="user.bill_recon.mobile_details"/>
                                </Grid>
                                <Grid item xs={12} md={6} className={styles.formColumn}>
                                    <TextField
                                        label={<IntlMessages id="user.bill_recon.mobile_number"/>}
                                        variant="outlined"
                                        {...register("mobile", {
                                            required: {
                                                value: isMobile,
                                                message: <IntlMessages id="errors.required"/>,
                                            },
                                        })}
                                        name="mobile"
                                        value={getValues().mobile}
                                        onChange={handleOnChange}
                                        error={errors.mobile}
                                        helperText={errors.mobile?.message}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} className={styles.formColumn}></Grid>
                            </Grid>
                        </Box>
                    )}
                    {isOther && (
                        <Box p={2}>
                            <Grid container xs={12} spacing={2}>
                                <Grid item xs={12} md={6} className={styles.formColumn}>
                                    <TextField
                                        multiline
                                        rows={7}
                                        label={<IntlMessages id="user.bill_recon.comment"/>}
                                        variant="outlined"
                                        {...register("comment", {
                                            required: {
                                                value: isOther,
                                                message: <IntlMessages id="errors.required"/>,
                                            },
                                        })}
                                        name="comment"
                                        value={getValues().comment}
                                        onChange={handleOnChange}
                                        error={errors.comment}
                                        helperText={errors.comment?.message}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} className={styles.formColumn}></Grid>
                            </Grid>
                        </Box>
                    )}
                    {(isBankTransferRequest || isReloadRequest) && (
                        <Box p={2}>
                            <Grid container xs={12} spacing={2}>
                                <Grid item xs={12} md={6} className={styles.formColumn}>
                                    <TextField
                                        label={<IntlMessages id="user.bill_recon.remarks"/>}
                                        variant="outlined"
                                        {...register("remarks", {
                                            required: {
                                                value: (isBankTransferRequest || isReloadRequest),
                                                message: <IntlMessages id="errors.required"/>,
                                            },
                                        })}
                                        name="remarks"
                                        value={getValues().remarks}
                                        onChange={handleOnChange}
                                        error={errors.remarks}
                                        helperText={errors.remarks?.message}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} className={styles.formColumn}></Grid>
                            </Grid>
                        </Box>
                    )}
                </div>
            </DialogModal>
        );
    } else {
        return null;
    }

};

export default BillRecon;
