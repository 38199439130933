import React, {useState, useEffect} from "react";
import useAxios from "../../hooks/useAxios";
import throttle from "lodash/throttle";
import {useIntl} from "react-intl";
import moment from "moment";
import {toTicketNumber, transformInToFormObject} from "services/utils";
import {useForm} from "react-hook-form";
import showAlert from "app/hooks/useAlerts";
import {Button, Typography} from "@material-ui/core";

const useData = ({lottery_id, lotteries, handleClose}) => {
    const axios = useAxios();

    const intl = useIntl();

    const format = (id) =>
        intl.formatMessage({
            id: `tickets.list.${id}`,
        });

    const [id, setId] = useState(0);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [columns, setColumns] = useState([]);
    const [selectionModel, setSelectionModel] = React.useState([]);
    const [filterModel, setFilterModel] = React.useState({items: []});
    const [options, setOptions] = React.useState([]);
    const [sortModel, setSortModel] = React.useState([
        {field: "id", sort: "asc"},
    ]);

    const defaultValues = {
        payment_type: null,
        account_number: "",
        reference_number: "",
    };

    const {
        register,
        getValues,
        formState: {errors},
        setValue,
        handleSubmit
    } = useForm({defaultValues});

    const formatDate = (params) =>
        moment(params.value).isValid()
            ? moment(params.value).format("yyyy-MM-DD HH:mm")
            : "";

    const getTicketNumber = (params) => {
        return toTicketNumber(params.row);
    };

    const getLotteryName = (params) => {
        return (lotteries.find((l) => l.value === params.value) || {}).label;
    };

    const getSerialNumber = (params) => {
        if (params === "prn_code") {
            return "prn_code";
        } else {
            return "code";
        }
    };

    const statusColor = (paid_status, id) => {
        console.log('paid', paid_status)
        if (paid_status === 'Paid') {
            return 'rgb(3,156,3)';
        } else if (paid_status === 'Unpaid') {
            return 'rgb(87,87,87)';
        } else if (paid_status === 'Inprogress'){
            return 'rgb(234,152,3)';
        }
    };

    // const getStatus = (params) => {
    //   const mappings = {
    //     0: format("status.pending"),
    //     1: format("status.issued"),
    //     2: format("status.received"),
    //     3: format("status.returned"),
    //   };
    //
    //   return mappings[params.value];
    // };

    useEffect(() => {
        const cols = [
                {
                    field: "serial_code", //getSerialNumber("prn_code") ? getSerialNumber("code") : "",
                    headerName: format("serial"),
                    flex: 1.2,
                },
                {
                    field: "lottery_id",
                    headerName: format("lottery_name"),
                    flex: 2,
                    valueFormatter: getLotteryName,
                },
                {
                    field: "char",
                    headerName: format("char"),
                    flex: 1,
                },
                {
                    field: "sno",
                    headerName: format("SNO"),
                    flex: 1,
                    hide: true,
                },
                {
                    field: "lagna",
                    headerName: format("lagna"),
                    flex: 1,
                    hide: true,
                },
                {
                    field: "winning_price",
                    headerName: format("winning_price"),
                    flex: 1,
                },
                {
                    field: "special_number",
                    headerName: format("special_number"),
                    flex: 1,
                    hide: true,
                },
                {
                    field: "ticket_number",
                    headerName: format("ticket_number"),
                    flex: 2,
                    valueGetter: getTicketNumber,
                    sortable: false,
                    filterable: false,
                },
                {
                    field: "paid_status",
                    headerName: format("status"),
                    flex: 1,
                    renderCell: (params) => (<Typography variant="subtitle2" style={{
                        display: 'flex',
                        whiteSpace: 'pre-line',
                        fontWeight: "bold",
                        fontSize: '15px',
                        marginBottom: '6px',
                        color: "white"
                    }}>
                        <Button variant="contained" color="primary" size="small"
                                style={{fontSize: '8px', background: statusColor(params.value), width: '80px'}}>
                            {params.value}
                        </Button>
                    </Typography>),
                },
                {
                    field: "drawnumber",
                    headerName:
                        format("draw_number"),
                    flex:
                        1,
                }
                ,
                {
                    field: "created_at",
                    headerName:
                        format("created_at"),
                    flex:
                        2,
                    valueFormatter:
                    formatDate,
                    hide:
                        true,
                }
                ,
                {
                    field: "purchase_date",
                    headerName:
                        format("purchase_date"),
                    flex:
                        2,
                    valueFormatter:
                    formatDate,
                }
                ,
            ]
        ;

        setColumns(cols);
    }, []);

    const handleSortModelChange = (params) => {
        if (params.sortModel !== sortModel) {
            setSortModel(params.sortModel);
            setPage(0);
        }
    };

    const handleFilter = (params) => {
        setFilterModel(params.filterModel);
        setPage(0);
    };

    const handleFilterChange = React.useCallback(throttle(handleFilter, 500), []);

    const handlePageChange = (params) => {
        setPage(params.page);
    };

    const handlePageCountChange = (params) => {
        setPageSize(params.pageSize);
        setPage(0);
    };

    const handleSelection = (newSelection) => {
        setSelectionModel(newSelection.selectionModel);
    };

    React.useEffect(() => {
        let active = true;

        if (!id) return;
        setLoading(true);

        (async () => {
            try {
                const response = await axios.post(
                    "/api/user/view/" + id
                );
                setLoading(false);
                if (active) {
                    if (response.status === 200) {
                        const banks = response.data.bank_accounts.map((bank) => ({
                            value: bank.id,
                            label: bank.account_name,
                        }));

                        setOptions(banks);
                    }
                }
            } catch (error) {
                setLoading(false);
            }
        })();

        return () => {
            active = false;
        };
    }, [id]);

    useEffect(() => {
        let active = true;

        (async () => {
            if (id < 1) return;
            setLoading(true);

            const body = {
                page_count: pageSize,
                user_id: id,
                sortModel,
                filterModel: {
                    items: filterModel.items
                        .filter((i) => i.value)
                        .map((i) => ({
                            ...i,
                            columnField: `tickets.${i.columnField}`,
                        })),
                },
                status: "maxwinnings",
            };

            try {
                const response = await axios.post(
                    "/api/ticket?page=" + (page + 1),
                    body
                );
                const newRows = response.data.data;
                const totalValue = response.data.total;

                if (!active) {
                    return;
                }

                setRows(newRows);
                setTotal(totalValue);
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        })();

        return () => {
            active = false;
        };
    }, [page, total, pageSize, id, sortModel, filterModel]);

    const save = async data => {
        setLoading(true);

        console.log('tdata', data)
        const ticket_id = selectionModel;
        try {
            const response = await axios.post(
                "api/user/holdtransaction/transfer/" + id,
                {
                    ticket_id,
                    ...data,
                    account_number: data.payment_type === 'bank_transfer' ? data.account_number : null
                }
            );

            if (response.status === 200) {
                showAlert('success', 'Funds transferred successfully')
                handleClose();
            }
        } catch (error) {
            setLoading(false);
        }
    }

    const submit = handleSubmit(save);

    return {
        rows,
        total,
        loading,
        pageSize,
        handlePageChange,
        handlePageCountChange,
        setRows,
        setId,
        handleSortModelChange,
        sortModel,
        handleFilterChange,
        columns,
        handleSelection,
        selectionModel,
        register,
        getValues,
        errors,
        setValue,
        options,
        submit
    };
};

export default useData;
