import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import makeStyles from '@material-ui/core/styles/makeStyles';
import IntlMessages from '@jumbo/utils/IntlMessages';

const useStyles = makeStyles(theme => ({
  tableCellRoot: {
    paddingLeft: 6,
    paddingRight: 6,
    paddingTop: 0,
    paddingBottom: 12,
    fontSize: 12,
    letterSpacing: 0.4,
    color: theme.palette.common.dark,
    fontWeight: theme.typography.fontWeightRegular,
    borderBottom: '0 none',
    '&:first-child': {
      paddingLeft: 14,
    },
    '&:last-child': {
      textAlign: 'right',
      paddingRight: 14,
    },
  },
}));

const TableHeading = () => {
  const classes = useStyles();
  return (
    <TableRow>
      <TableCell m="auto" className={classes.tableCellRoot}><IntlMessages id="revenue.agent.transdate" /></TableCell>
      <TableCell m="auto" className={classes.tableCellRoot}><IntlMessages id="revenue.agent.credit" /></TableCell>
      <TableCell m="auto" className={classes.tableCellRoot}><IntlMessages id="revenue.agent.debit" /></TableCell>
        <TableCell m="auto" className={classes.tableCellRoot}><IntlMessages id="revenue.agent.balance" /></TableCell>
      <TableCell m="auto" className={classes.tableCellRoot}><IntlMessages id="revenue.agent.comment" /></TableCell>
     </TableRow>
  );
};

export default TableHeading;
